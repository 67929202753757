<template>
  <router-view />
</template>

<script>

import enquireScreen from '@/utils/device'

export default {
  name: 'App',
  setup() {

    /*console.log(navigator.userAgent)
    if(navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1){
      console.log('是微信')
    }else {
      console.log('不是微信', navigator.userAgent.toLowerCase())
    }*/

    enquireScreen(deviceType => {
      console.log(deviceType)
      // tablet
      /*if (deviceType === 0) {
        that.$store.commit('TOGGLE_DEVICE', 'mobile')
        that.$store.dispatch('setSidebar', false)
      }
      // mobile
      else if (deviceType === 1) {
        that.$store.commit('TOGGLE_DEVICE', 'mobile')
        that.$store.dispatch('setSidebar', false)
      }
      else {
        that.$store.commit('TOGGLE_DEVICE', 'desktop')
        that.$store.dispatch('setSidebar', true)
      }*/

    })
  }
}
</script>

<style>
</style>
