import { createApp } from 'vue'
import router from './router/index'
import App from './App.vue'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

document.onselectstart = function () { return false; }


const app = createApp(App)
// 注册ElementPlus
app.use(ElementPlus, {
    locale, // 语言设置
    size: 'medium' // 尺寸设置
})

// 注册 ElementPlusIcons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// import weui from 'weui.js'
// import 'weui'
// app.config.globalProperties.$weui = weui

app.use(router).mount('#app')
