import Cookies from 'js-cookie'

const TokenKey = 'weixin-web'

export function getToken(key) {
    return Cookies.get(key || TokenKey) || ''
}

export function setToken(token) {
    return Cookies.set(TokenKey, token, {
        expires: 7
    })
}

export function setTokenByKey(key, token) {
    return Cookies.set(key, token, {
        expires: 7
    })
}


export function removeToken(key) {
    return Cookies.remove(key || TokenKey)
}
