import { createRouter, createWebHistory } from 'vue-router'
import { getToken } from '@/utils/CookieUtils'

const routerHistory = createWebHistory()

export const adminRoutes = [
  {
    path: '/admin/weChatReleaseList',// 管理也首页
    component: () => import('../views/admin/article/weChatReleaseList'),
    meta: {
      title: '微信文章',
      isLogin: true
    }
  },
  {
    path: '/admin/articleList',// 管理也首页
    component: () => import('../views/admin/article/articleList'),
    meta: {
      title: '发布文章',
      isLogin: true
    }
  },
  {
    path: '/admin/userList',// 管理也首页
    component: () => import('../views/admin/user/userList'),
    meta: {
      title: '关注用户',
      isLogin: true
    }
  },
]

const routes=[
  /* 管理后台网页路由 */
  {
    path: '/admin',// 管理首页
    component: () => import('../views/admin/adminIndex'),
    meta: {
      title: '管理首页',
      isLogin: true
    },
    children: adminRoutes
  },
  {
    path: '/admin/login',// 管理登陆
    component: () => import('../views/admin/adminLogin'),
    meta: {
      title: '管理后台登陆',
      isLogin: false
    }
  },
  {
    path: '/admin/messagePage',// 用户点击确定跳转页面
    component: () => import('../views/admin/messagePage'),
    meta: {
      title: '管理后台登陆',
      isLogin: false
    }
  },

  /* 微信网页路由 */
  //这里和vue2一样
  {
    path: '/',// 认证信息
    component: () => import('../views/wx/homePage'),
    meta: {
      isLogin: false
    }
  },
  {
    path: '/ArticleList',// 文章列表页
    component: () => import('../views/wx/article/ArticleList'),
    meta: {
      title: '预测旬报',
      isLogin: true
    }
  },
  {
    path: '/ArticleInfo',// 文章详情
    component: () => import('../views/wx/article/ArticleInfo'),
    meta: {
      title: '旬报详情',
      isLogin: true
    }
  },
  {
    path: '/lngCharts',// LNG预测
    component: () => import('../views/wx/lng/LngCharts'),
    meta: {
      title: '预测日报',
      isLogin: true
    }
  },
  {
    path: '/login',
    component: () => import('../views/wx/login/loginSuccessPage'),
    meta: {
      title: '登录页',
      isLogin: false
    },
    children: [

    ]
  },
  {
    path: '/subscribeHint',// 关注提示
    component: () => import('../views/wx/login/subscribeHint'),
    meta: {
      title: '关注提示',
      isLogin: false
    }
  },{
    path: '/CheckQRCode',// 关注提示
    component: () => import('../views/wx/login/CheckQRCode'),
    meta: {
      title: '验证信息',
      isLogin: false
    }
  },
  {
    path: '/404',
    component: () => import('../views/base/error/404'),
    meta: {
      title: '页面不存在',
      isLogin: false
    }
  },
  {
    path: '/401',
    component: () => import('../views/base/error/401'),
    meta: {
      title: '无权限',
      isLogin: false
    }
  }
]
const router = createRouter({
  history: routerHistory,
  routes
})

router.beforeEach((to, from, next) => {
  // 1. 每个条件执行后都要跟上 next() 或 使用路由跳转 api 否则页面就会停留一动不动
  // 2. 要合理的搭配条件语句，避免出现路由死循环。
  window.document.title = to.meta.title == undefined?'LNG市场仿真':to.meta.title

  // console.log(to.path, !token, to.meta.isLogin)
  if (to.meta.isLogin === undefined){
    return router.replace({
      path: '/404'
    })
  }else {
    if (to.path.indexOf('admin') > 0){
      const token = getToken('huahualuomu')
      console.log('是管理后台地址',token, to.path)
      if (to.meta.isLogin){
        if (!token) {
          return router.replace({
            path: '/admin/login'
          })
        }else{
          next()
        }
      }else {
        if (token) {
          if (to.path === '/admin/login'){
            return router.replace({
              path: '/admin/ArticleList'
            })
          }else {
            next()
          }
        }else {
          next()
        }
      }
    }else {
      if(to.path === '/401' || navigator.userAgent.toLowerCase().indexOf('micromessenger') > -1){
        console.log('是微信')
      }else {
        return router.replace({
          path: '/401'
        })
      }
      const token = getToken()
      // console.log('token: ',token, ' path: ',to.path)
      if (to.meta.isLogin){
        if (!token) {
          return router.replace({
            path: '/login',
            query: {'path': to.path}
          })
        }else{
          next()
        }
      }else {
        if (token) {
          if (to.path === '/login'){
            console.log(to.query)
            if (!to.query.path){
              return router.replace({
                path: '/ArticleList'
              })
            }else {
              return router.replace({
                path: to.query.path
              })
            }
          }else {
            next()
          }
        }else {
          next()
        }
      }
    }
  }

})

export default router
